import styled from "styled-components"

export const DivAbout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    color: #D8DEE7;
    /* color: #0D0D0D; */
    padding-top: 2rem;
    width: 100%;
    /* border: solid white 2px; */
    /* background: solid #0D0D0D 2px; */
    /* height: 50%; */
    text-align: center;
    line-height: 1.5;
    /* z-index: 2000; */
    div{
        width: 70%;
        margin: 3rem auto;
        /* text-align: justify; */
    }
    h1{
        color: #D8DEE7
    }
    p{
        padding-bottom: 2rem;
    }

`