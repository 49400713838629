import styled from "styled-components"

export const Hero = styled.div`
    height: 100vh;
    width: 100%;
    object-fit: contain;
`

export const Content = styled.div`
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
    .btn{
        padding: 12px 32px;
        font-size: 1.2rem;
        text-transform: uppercase;
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        cursor: pointer;
        &:hover{
            background: rgba(255, 255, 255 , .2);
            transition: ease-in-out .5s
            /* transition-duration: .5s; */
        }
    }
    .btn-light{
        background: rgba(255, 255, 255 , .2);
    }
`

export const Motow = styled.p`
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color: #fff;

`

export const Title = styled.h1`
    font-size: 4rem;
    color: #BB9D63;


`
export const TheVideo = styled.video`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
`

export const DarkOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
`
export const FadeToBlack = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20vh; // Ajustez cette valeur pour contrôler la hauteur du gradient
  background: linear-gradient(to top, black 0%, transparent 100%);
  z-index: 1;
`;

// export const About = styled.div`
//     padding-top: 2rem;
//     width: 100%;
//     background: solid white 2px;
//     /* background: solid #774441 2px; */
//     height: 50%;
//     text-align: center;
//     /* z-index: 2000; */
// `