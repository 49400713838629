import React from "react";
import { HashRouter as Router, Routes, Route  } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import Home from "./routes/Home";
// import Offres from "./components/Offres";


function App() {
  	return(
		<>
			<Router>
				<Routes>
					<Route path="/" element={<Home />}/>	
					{/* <Route path="/offres" element={<Offres />}/>	
					<Route path="/about" element={<About />}/>	
					<Route path="/contact" element={<Contact />}/>	  */}
				</Routes>
			</Router>
		</>
	)
	
}
	
export default App;
