// HoverCard.style.js
import styled from "styled-components";

// console.log(props);

export const StyledH1 = styled.h1`
margin-bottom: 2rem;
  text-align: center;
`;


export const CardWrap = styled.div`
  margin: 20px; 
  width: 100%;
  transform: perspective(800px);
  transform-style: preserve-3d;
  cursor: pointer;
  /* Add hover styles etc. */
  /* background-color: red */
  &:hover{
    .card-to-glow{
      border: 2px solid ${props => props.halo};
      box-shadow: 0 0 10px ${props => props.halo}, 
                0 0 20px ${props => props.halo}, 
                0 0 30px ${props => props.halo}, 
                0 0 40px ${props => props.halo};
    }
  }
`

export const Card = styled.div`
  position: relative;
  flex: 0 0 240;
  /* width: 30%; */
  height: 320px; 
  background-color: #333;
  overflow: hidden;
  border-radius: 10px;
  transition: transform .5s ease-out; 
  /* Add transition styles etc. */
`;

export const CardBg = styled.div`
  opacity: .55;
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  padding: 20px;
  background-repeat: no-repeat;
  /* background-position: center; */
  background-position: center top;
  background-size: cover;
  /* Add transition styles etc. */
`;

export const CardInfo = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  color: #fff;
  /* Add transition styles etc. */
`;


export const AllProducts = styled.div`
  /* background-color: rgba(255, 0, 0, 0.2); */

  width: 90%;
  margin: auto;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px){
        flex-direction: column;
  }
`