import React from 'react'
import '../styles/Video.style'
import { Link } from 'react-scroll'
import photoboothVideo from '../assets/Template_Film_Khass.mp4'
import { 
    Hero,
    Content,
    Motow,
    Title,
    TheVideo,
    DarkOverlay,
    FadeToBlack

} from "../styles/Video.style" 


const Video = () => {
  return (
    <>
        <Hero id="video">
            <TheVideo autoPlay loop muted id='videoBanner'>
                <source src={photoboothVideo} type='video/mp4' />
            </TheVideo>
            <DarkOverlay />
            <FadeToBlack />
            <Content>
                <Title>Prestige Event</Title>
                <Motow>Vivez vos photos</Motow>
                <div>
                    <Link to="FormulaireContact" smooth={true} duration={500} className='btn'>Contact</Link>
                    <Link to='produits' smooth={true} duration={500} className='btn btn-light'>Offres</Link>
                </div>
            </Content>
        </Hero>
        {/* <About>
            <h1>A propos de nous</h1>
            <div>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam asperiores quidem qui dolor eius aliquid non, similique voluptates corrupti illo excepturi. Fugit, magnam. Beatae, nulla incidunt! Iste quisquam deleniti debitis!
                Corporis quam perspiciatis ipsam qui dolorem repellendus ipsum quod ab molestiae. Aliquid minima sunt odio est porro labore eveniet vero adipisci perferendis molestiae! Rerum nobis neque aspernatur nostrum, modi enim.
                Natus praesentium dolore ipsum maiores consectetur velit, maxime, atque deleniti facilis eum ea ducimus voluptatibus neque? Corrupti assumenda necessitatibus ab eum exercitationem mollitia illum quam repellendus, neque deleniti quod deserunt?
                Facere debitis qui dicta adipisci quaerat delectus molestias perspiciatis voluptates quae amet alias officiis quidem corrupti eveniet consequatur architecto odit, ipsam, voluptatum repudiandae quasi ullam deserunt voluptatem blanditiis sit. Iure.
                Fugiat optio obcaecati tempore impedit rem accusantium culpa magni iure voluptas itaque nemo vitae maxime, similique perferendis distinctio dolorum est, sapiente omnis odio labore eum odit perspiciatis quibusdam. Excepturi, accusantium.
            </div>
        </About> */}
    </>
  )
}

export default Video