import React, {useState, useEffect} from 'react' 
import { 
    Header,
    NavLink,
    NavMenu,
    Hamburger,
    NavMenuLi,
} from "../styles/Navbar.style" 
import {FaBars, FaTimes} from "react-icons/fa"
// import ContactUs from './ContactForm'
// import Logo from "../assets/event_khass.svg"
import Logo from "../assets/justLogo.svg"
// import Logo from "../assets/event_khass1.svg"
// import Logo from "../assets/event_khass2.svg"

const Navbar = () => {

    const [click, setClick] = useState(false);
    const [blur, setBlur] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const video = document.getElementById('videoBanner');
            if (video) {
                const videoHeight = video.offsetHeight;
                if (window.scrollY > videoHeight) {
                    setBlur(true);
                } else {
                    setBlur(false);
                }
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = () => {setClick(!click)}    

    return (
        <Header blur={blur}>
            <NavLink>
                {/* <Title>Pres Event</Title> */}
                <img src={Logo} alt="logo" height={"90px"} loading="lazy"/>
            </NavLink>
            <NavMenu active={click}>
                {console.log(click, 'click')}
                    <NavLink to="video" smooth={true} duration={500}>
                        <NavMenuLi>
                                Accueil
                        </NavMenuLi>
                    </NavLink>
                    {/* <NavLink to="/about">A propos</NavLink> */}
                    <NavLink to="aboutUs" smooth={true} duration={500}>
                        <NavMenuLi>
                                About
                        </NavMenuLi>
                    </NavLink>
                    <NavLink to="offres" smooth={true} duration={500}>
                        <NavMenuLi>
                                Offres
                        </NavMenuLi>
                    </NavLink>
                    <NavLink to="FormulaireContact" smooth={true} duration={500}>
                        <NavMenuLi>
                                Contact
                        </NavMenuLi>            
                    </NavLink>
            </NavMenu>
            <Hamburger onClick={handleClick}>
                {click ? (<FaTimes size = {20} style={{color: 'white'}}/>) : (<FaBars size = {20} style={{color: 'white'}} />)}                
            </Hamburger>
        </Header>
    )
}

export default Navbar