// import { useForm, ValidationEor } from "@formspree/react";
import { useState } from "react";
// import { contactConfig } from "../contentOptions"
// import { ContactFormStyle } from "../styles/ContactForm.style";
import {
	BigContainer,
	Title,
	FormAndTouch,
	Form,
	Touch,
	NomEmail,
	SubMsg,
	SubmitButton,
} from "../styles/ContactForm.style";

const ContactUs = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: new URLSearchParams(formData).toString(),
		})
			.then((response) => {
				if (response.ok) {
					setIsSubmitted(true);
				} else {
					setErrorMessage("Something went wrong. Please try again later.");
				}
			})
			.catch((error) => {
				setErrorMessage("An error occurred. Please try again later.");
				console.log("error: ", error);
			});
	};

	return (
		<>
			<BigContainer id="FormulaireContact">
				<Title>Nous Contacter</Title>
				<FormAndTouch>
					<Touch>
						<h1>Restons en contact</h1>

						<h3>Email : {process.env.REACT_APP_EMAIL}</h3>
						<h3>Tel : {process.env.REACT_APP_FONE}</h3>
						<p>{process.env.REACT_APP_DESCRIPTION}</p>
					</Touch>
					{/* <SubMsg><h3>Votre message a été envoyé avec succès ! Nous avons bien reçu vos coordonnées et nous vous répondrons dans les plus brefs délais. <br/><br/> Merci de nous avoir contactés.</h3></SubMsg> */}
					{isSubmitted ? (
						<SubMsg>
							<h3>
								Votre message a été envoyé avec succès ! Nous avons bien reçu
								vos coordonnées et nous vous répondrons dans les plus brefs
								délais. <br />
								<br /> Merci de nous avoir contactés.
							</h3>
						</SubMsg>
					) : (
						<Form
							method="post"
							name="contact"
							data-netlify="true"
							onSubmit={handleSubmit}
						>
							<input type="hidden" name="form-name" value="contact" />
							<NomEmail>
								<input type="text" placeholder="Nom" name="name" id="nom" />
								<input
									type="email"
									placeholder="Email"
									name="email"
									id="email"
								/>
							</NomEmail>
							<textarea
								name="message"
								id="message"
								cols="30"
								rows="10"
								placeholder="Entrez votre message"
							></textarea>
							{/* <input type="textArea" placeholder="Message" name="message" id="message"/> */}
							<SubmitButton type="submit" id="submitButton" />
						</Form>
					)}
					{errorMessage && <div>{errorMessage}</div>}
				</FormAndTouch>
			</BigContainer>
		</>
	);
};

export default ContactUs;

// import React, { useState } from "react";
// import {
// 	BigContainer,
// 	Title,
// 	FormAndTouch,
// 	Touch,
// 	Form,
// 	NomEmail,
// 	SubMsg,
// 	SubmitButton,
// } from "../styles/ContactForm.style";

// const ContactForm = () => {
// 	const [formData, setFormData] = useState({
// 		name: "",
// 		email: "",
// 		message: "",
// 	});
// 	const [isSubmitted, setIsSubmitted] = useState(false);
// 	const [errorMessage, setErrorMessage] = useState("");

// 	const handleChange = (e) => {
// 		const { name, value } = e.target;
// 		setFormData({ ...formData, [name]: value });
// 	};

// 	const handleSubmit = (e) => {
// 		e.preventDefault();
// 		const { name, email, message } = formData;
// 		if (!name || !email || !message) {
// 			setErrorMessage("Tous les champs sont obligatoires.");
// 			return;
// 		}
// 		setErrorMessage("");
// 		const whatsappMessage = `Nom: ${name}%0AEmail: ${email}%0AMessage: ${message}`;
// 		const whatsappUrl = `https://wa.me/33635597862?text=${whatsappMessage}`;
// 		window.open(whatsappUrl, "_blank");
// 		setIsSubmitted(true);
// 	};

// 	return (
// 		<BigContainer id="FormulaireContact">
// 			<Title>Nous Contacter</Title>
// 			<FormAndTouch>
// 				<Touch>
// 					<h1>Restons en contact</h1>
// 					<h3>Email : {process.env.REACT_APP_EMAIL}</h3>
// 					<h3>Tel : {process.env.REACT_APP_FONE}</h3>
// 					<p>{process.env.REACT_APP_DESCRIPTION}</p>
// 				</Touch>
// 				{isSubmitted ? (
// 					<SubMsg>
// 						<h3>
// 							Votre message a été envoyé avec succès ! Nous avons bien reçu vos
// 							coordonnées et nous vous répondrons dans les plus brefs délais.{" "}
// 							<br />
// 							<br /> Merci de nous avoir contactés.
// 						</h3>
// 					</SubMsg>
// 				) : (
// 					<Form
// 						method="post"
// 						name="contact"
// 						data-netlify="true"
// 						onSubmit={handleSubmit}
// 					>
// 						<input type="hidden" name="form-name" value="contact" />
// 						<NomEmail>
// 							<input
// 								type="text"
// 								placeholder="Nom"
// 								name="name"
// 								id="nom"
// 								value={formData.name}
// 								onChange={handleChange}
// 							/>
// 							<input
// 								type="email"
// 								placeholder="Email"
// 								name="email"
// 								id="email"
// 								value={formData.email}
// 								onChange={handleChange}
// 							/>
// 						</NomEmail>
// 						<textarea
// 							name="message"
// 							id="message"
// 							cols="30"
// 							rows="10"
// 							placeholder="Entrez votre message"
// 							value={formData.message}
// 							onChange={handleChange}
// 						></textarea>
// 						<SubmitButton type="submit" id="submitButton" value="Envoyer" />
// 					</Form>
// 				)}
// 				{errorMessage && <div>{errorMessage}</div>}
// 			</FormAndTouch>
// 		</BigContainer>
// 	);
// };

// export default ContactForm;
