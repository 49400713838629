import React from 'react'
import { DivAbout } from '../styles/About.style'


const About = () => {
    return (
            <DivAbout id="aboutUs">
                <h1>A propos de nous</h1>
                <div>
                    <p>Chez Prestige Event, nous sommes passionnés par la capture de moments joyeux et la création de souvenirs durables. Lancée en janvier 2023, notre entreprise est née de notre amour pour les événements festifs et notre désir de permettre à tous de vivre une expérience de cérémonie digne d'un tapis rouge.</p>

                    <p>Notre marché de niche est celui du vidéobooth, un concept encore peu répandu en France, mais qui gagne rapidement en popularité grâce à son caractère innovant et divertissant. Ce qui distingue Prestige Event, c'est la qualité exceptionnelle de nos prestations, combinée à l'attractivité de nos formules tarifaires. Nous nous engageons à offrir un service haut de gamme sans compromettre l'accessibilité.</p>

                    <p>L'équipe Prestige Event, bien que petite, est composée des deux fondateurs dévoués et passionnés par ce qu'ils font. Nos valeurs reposent sur l'importance de la famille et de la joie de partager de bons moments avec nos proches. Nous croyons fermement à la rigueur et au professionnalisme, et ces principes guident chaque aspect de notre travail.</p>

                    <p>Nos vidéobooths sont équipés de photomatons rotatifs à 360° avec un iPhone pour une qualité photo et vidéo exceptionnelle, ainsi que d'un dispositif iPad permettant de voir et de modifier les photos et les vidéos en temps réel. Ce niveau de technologie et de flexibilité est rare dans l'industrie, et nous sommes fiers de l'offrir à nos clients.</p>

                    <p>Bien que nous soyons une entreprise récente, nous avons déjà reçu de nombreux commentaires positifs de la part de nos clients, qui apprécient notre professionnalisme, la qualité de nos services, et l'originalité de notre concept.</p>

                    <p>Nous vous invitons à entrer en contact avec nous pour en savoir plus sur nos services et découvrir comment nous pouvons rendre votre prochain événement inoubliable. Vous pouvez nous appeler directement ou remplir le formulaire de contact sur notre site pour nous laisser un message. Nous avons hâte de travailler avec vous !</p>                
                </div>
            </DivAbout>
    )
}

export default About