import React from "react";
import Navbar from "../components/Navbar";
import Video from "../components/Video";
// import Offres from "../components/Offres"
import ContactUs from "../components/ContactForm";
import FullOffer from "../components/FullOffer"
import About from "../components/About";

const Home = () => {
    return (
        <>
            <Navbar />  
            <Video />
            <About/>
            <FullOffer />
            <ContactUs />
        </>
    )
};

export default Home;
