import styled from "styled-components"


export const BigContainer = styled.div`
  /* height: 100vh; */
  background-color: #0D0D0D;
  width: 90%;
  position: inherit;
  margin: auto;
  margin-bottom: 3rem;
  /* height: 100vh; */
  padding-top:  3rem;

  @media (max-width: 768px) {
    width: 95%;
    padding-top: 1.5rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding-top: 1rem;
  }
`

export const Title = styled.h1`
  margin: auto auto auto 5rem;
  /* font-size: larger; */
  font-weight: bolder;
  font-size: 50px;
  font-family: 'Raleway';
  @media (max-width: 768px) {
    text-align: center;
    margin-left: 0;
  }
`

export const FormAndTouch = styled.div`
    /* border : solid white 2px; */
    /* width: 100%; */
    margin: 0 5rem ;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1240px) {
    flex-direction: column-reverse; /* Reverse order for small screens */
    margin: 1.5rem 2.5rem;
  }

  @media (max-width: 480px) {
    margin: 1rem 1.5rem;
  }
`

export const Form = styled.form`
    padding: 2.5rem 5rem;
    /* border : solid white 2px; */
    width: 60%;
    display: flex;
    flex-direction: column;
    textarea{
      background-color: transparent;
      margin-top: 2rem;
      padding: 1rem;
      border: solid #D8DEE7 1px;
      color: #D8DEE7;
    }
    input{
      margin-top: 2rem;
      width: 5rem;
      height: 1.5rem;
      /* cursor: pointer; */
      &:hover{
        /* background-color: #BB9D63; */
        color: #D8DEE7;
        font-size: 600;
      }
    }

    /* @media (min-with : 600px, max-width: 768px) {
      width: 80%;
      padding: 2.5rem 0
    } */
    @media (max-width: 768px) {
      width: auto;
      padding: 2.5rem 0
    }
    @media (min-width: 769px) and (max-width: 1239px) {
      width: auto;
    }
`

export const NomEmail = styled.div`
    display: flex;
    justify-content: space-between;
    padding: auto 2rem;
    /* margin-bottom: 2rem; */
    input{
      padding-left: 1rem;
      background-color: transparent;
      color: #D8DEE7;
      border: solid #D8DEE7 1px;
      width: 45%;
      height: 50px;
    }
    
    @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;

    input {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

`

export const Touch = styled.div`
    /* border : solid white 2px; */
    padding: 6rem 5rem 5rem;
    width: 40%;
    color: #D8DEE7;
    h1{
      margin-bottom: 2.5rem;
    }
    h3{
      margin-bottom: 1.5rem;
      /* font-family: Lato; */
    }
    p{
      margin-top: 2rem;
    }

    @media (max-width: 1240px) {
      width: 100%;
      margin-bottom: 1.5rem; /* Add some spacing between Touch and Form on small screens */
    }
`

export const SubMsg = styled.div`
  /* border: solid white 2px; */
  padding:  5rem ;
  color : #D8DEE7;
  width: 60%;
  h3{
    text-align: center;
    justify-content: center;
    /* align-items: center;  */
  }
`

export const SubmitButton = styled.input`
  cursor: pointer;
  border: solid 2px;
  border-radius: 10px;
  width: 5rem;
  background-image: linear-gradient(to right bottom, #bb9d63, #bfb14c, #b1ca38, #8be434, #00ff4c);
  &:hover {
        /* background: #DBCA98; */
        border-radius: 10px;
        box-shadow: 0 0 5px #bb9d63,
                    0 0 25px #bfb14c,
                    0 0 50px #8be434,
                    0 0 50px #00ff4c;
        transition: background 1s ease, border-radius .3s ease, box-shadow 1s ease;
        color: black;
    }

`












// export const ContactFormStyle = styled.div`
//     color: #D8DEE7;
//     padding-top: 3rem;
//     width: 100%;
//     background: solid white 2px;
//     /* background: solid #774441 2px; */
//     height: 50%;
//     text-align: center;
//     line-height: 1.5;
//     /* z-index: 2000; */
//     div{
//         width: 70%;
//         margin: 3rem auto;
//         /* text-align: justify; */
//     }
//     h1{
//         color: #D8DEE7
//     }
//     p{
//         padding-bottom: 2rem;
//     }

// `


////First try whith bootstrap thing

// export const PageContainer = styled.div`
//   /* Vos styles de container ici */
// `;

// export const FlexRow = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// `;

// export const FlexColumn = styled.div`
//   padding: 0 15px;
//   flex-basis: 0;
//   flex-grow: 1;
//   max-width: 100%;
//   flex: ${(props) => props.size ? `0 0 ${props.size * 100 / 12}%` : "auto"};
//   max-width: ${(props) => props.size ? `${props.size * 100 / 12}%` : "auto"};
// `;

// export const ContactForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
// `;

// export const FormInput = styled.input`
//   padding: .375rem .75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   color: #495057;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #ced4da;
//   border-radius: .25rem;
//   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// `;

// export const MessageTextarea = styled.textarea`
//   min-height: 120px;
//   padding: .375rem .75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   color: #495057;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #ced4da;
//   border-radius: .25rem;
//   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// `;

// export const SubmitButton = styled.button`
//   color: #fff;
//   background-color: #007bff;
//   border-color: #007bff;
//   display: inline-block;
//   font-weight: 400;
//   text-align: center;
//   vertical-align: middle;
//   user-select: none;
//   border: 1px solid transparent;
//   padding: .375rem .75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   border-radius: .25rem;
//   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// `;

// //End bootstrap thing


