import React from 'react'
import HoverCard from './HoverCard'
import { AllProducts, StyledH1 } from "../styles/HoverCard.style";
import image1 from "../assets/2_niggas_photobooth.png"
import image2 from "../assets/2_whites_dancing.jpeg"
import image3 from "../assets/3_queens_dancing.webp"

const FullOffer = () => {
  return (
    <>
      <StyledH1 id="offres">Nos formules</StyledH1>
      <AllProducts id='produits'>
        <HoverCard image={image1} header={"1-2 PERS"} content={"290€ / 5h"} halo={'#FB8136'}/>
        <HoverCard image={image2} header={"3-7 PERS"} content={"319€ / 5h"} halo={'#DDDCEF'}/>
        <HoverCard image={image3} header={"1-7 PERS"} content={"349€ / Soirée complète"} halo={'#F2AB46'}/>
      </AllProducts>
    </>
  )
}

export default FullOffer