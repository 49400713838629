// HoverCard.jsx
import React, { useState, useRef, useEffect } from "react";
import { CardWrap, Card, CardBg, CardInfo } from "../styles/HoverCard.style";


const HoverCard = ({ image, header, content, halo }) => {
  console.log(halo, "halo")
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const cardRef = useRef(null);

  useEffect(() => {
    console.log(cardRef.current.offsetWidth, cardRef.current.offsetHeight);
    setWidth(cardRef.current.offsetWidth);
    setHeight(cardRef.current.offsetHeight);
  }, []);

  const handleMouseMove = (e) => {
    const x = e.pageX - cardRef.current.offsetLeft - width / 2;
    const y = e.pageY - cardRef.current.offsetTop - height / 2;
    setMouseX(x);
    setMouseY(y);
  };

  const cardStyle = {
    transform: `rotateY(${(mouseX / width) * 30}deg) rotateX(${(mouseY / height) * -30}deg)`,
  };

  const cardBgTransform = {
    transform: `translateX(${(mouseX / width) * -40}px) translateY(${(mouseY / height) * -40}px)`,
  };

  const cardBgImage = {
    backgroundImage: `url(${image})`,
  };

  const handleMouseLeave = () => {
    setMouseX(0);
    setMouseY(0);
  };

  return (
    <CardWrap halo ={halo}
      ref={cardRef} 
      onMouseMove={handleMouseMove} 
      onMouseLeave={handleMouseLeave}  
    >
      <Card className="card-to-glow" style={cardStyle}>
        <CardBg style={{ ...cardBgTransform, ...cardBgImage }}></CardBg>
        <CardInfo>
          <h1>{header}</h1>
          <p>{content}</p>
        </CardInfo>
      </Card>
    </CardWrap>
  );};

export default HoverCard;