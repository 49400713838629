import styled from "styled-components"
// import { Link } from "react-scroll"
import { Link } from "react-scroll"

export const Header = styled.div`
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
    backdrop-filter: ${props => props.blur ? 'blur(10px)' : 'none'};
    background: ${props => props.blur ? 'rgba(0, 0, 0, 0.6)' : 'transparent'}; // change 0.6 to control darkness
`

export const NavLink = styled(Link)`
    color: #D8DEE7;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    
        //&:hover{    
            /* filter: drop-shadow(10px 10px 20px white); */
          //      filter: drop-shadow(0 0 0.75rem #AB8508);
            //    -webkit-filter: drop-shadow(10px 10px 20px 20px #A52A2A);
              //  /* -webkit-filter: drop-shadow(10px 10px 20px 20px #F5F5DC); */
                //transition: all .5s ease-in-out .5s; 
        //}

    @media (max-width: 1240px){
        font-size: 2rem;
    }

`

export const Title = styled.h1`

`

export const NavMenu = styled.ul`
    display: flex;

    @media (max-width: 1240px){
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0%;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background: rgba(0,0,0, .9);

        /* &:active { */
            left: ${props => props.active ? '0' : '-100%'};
        /* } */
    }
`

export const NavMenuLi = styled.li`
    padding: .5rem 1.5rem;

    &:hover {
        background: #DBCA98;
        border-radius: 5px;
        box-shadow: 0 0 5px #fff,
                    0 0 25px #fff,
                    0 0 50px #fff,
                    0 0 50px #fff;
        transition: background 1s ease, border-radius .3s ease, box-shadow 1s ease;
        color: #000000;

        /* ${NavLink} {
        } */
    }

    @media (max-width: 1240px){
        padding: 1rem 0;
    }
`




export const Hamburger = styled.div`
    display: none;

    @media (max-width: 1240px){
        display: block
    }
`


